<template>
	<div class="carrierWrap" v-bind:class="{ 'expanded': (expanded === true), 'lowestPrice': (insquote.isLowest === true), 'hasError': (isError === true) }">
		<div class="carrierBasics row">
			<img class="lpCorner" :src="asset('lowestPrice-orange.png')" alt="Lowest Price" v-if="(insquote.isLowest === true)">
			<div class="carrierLogo1" :class="{ 'noPointer': hasTotal === false }" v-on:click="expand()">
				<img :src="insquote.carrier_logo_url" :alt="insquote.carrier_name">
			</div>
			<div class="carrierProducts1" v-bind:class="{ 'mobilehide': (hasTotal === false) }">
				<div v-bind:class="{ 'autoResult': (quotetype == 'Auto'), 'homeResult': (quotetype == 'Home') }">
					<div class="productPriceBar">
						<div class="quoteIconWrap" v-if="quotetype == 'Auto'"><icon-helper :glyph="'auto'" :variant="iconvariant" :classes="'carIcon'"></icon-helper></div>
						<div class="quoteIconWrap" v-if="quotetype == 'Home'"><icon-helper :glyph="'home'" :variant="iconvariant" :classes="'houseIcon'"></icon-helper></div>
						<div class="quotePrice" v-if="hasTotal">
							<span class="dollarSign">$</span>
							<span class="priceNumber" v-html="numFormat(coverages.total)"></span>
							<span class="priceUnit">/<span v-html="insquote.totalUnit"></span><a href="#" v-on:click.prevent="scrollToDisclaimer()" class="disclaimerStar noTrack"><span class="latinCross">&#10013;&#xFE0E;</span></a></span>
						</div>
					</div>
					<div class="productTermBar" v-if="hasTotal">
						Total <span v-text="singleQuote.term">XX</span> month premium $<span v-html="numFormat(singleQuote.term_premium)">XX</span>
					</div>
					<div class="detailsLinkWrap" v-show="hasTotal === true">
						<a href="#" class="detailsLink noTrack" v-on:click.prevent="expand()">Coverage Details</a>
					</div>
				</div>
			</div>
			<div class="carrierPrice1 carrierCtaCell">
				<div class="bigPrice" v-if="hasTotal === true">
					<a class="bluebutton callbutton" :href="'tel:' + phonedigits" data-linkname="Call now"><icon-helper :glyph="'phoneHeadset'" :variant="'ui'"></icon-helper>Call to Purchase</a>
				</div>
				<div class="noPriceMesg" v-if="hasTotal === false && insquote.preferred !== true">
					<div class="alertIconWrap">
						<icon-helper :glyph="'phoneHeadset'" :variant="'ui'" :classes="'alertIcon'"></icon-helper>
					</div>
					We are unable to provide a quote online. {{ callIM_cta }}<span class="doubleDagger">&#8225;</span>
				</div>
				<div class="noPriceMesg" v-if="hasTotal === false && insquote.preferred === true">
					We are unable to obtain this quote online at this time. Please call {{ tel }} for more information.<span class="doubleDagger">&#8225;</span>
				</div>
			</div>
		</div>
		<div class="carrierDetails">
			<img class="closeX" :src="closeXimage" alt="(X)" v-on:click="collapse()">
			<div>
				<div class="coveragesRow">
					<div class="coverageGroup">
						<p>
							<strong>{{ coverageLabels.a }}</strong><br>
							<span v-html="amount(coverages.a)"></span>
						</p>
						<p>
							<strong>{{ coverageLabels.c }}</strong><br>
							<span v-html="amount(coverages.c)"></span>
						</p>
						<p>
							<strong>{{ coverageLabels.e }}</strong><br>
							<span v-html="amount(coverages.e)"></span>
						</p>
					</div>
					<div class="coverageGroup">
						<p>
							<strong>{{ coverageLabels.b }}</strong><br>
							<span v-html="amount(coverages.b)"></span>
						</p>
						<p>
							<strong>{{ coverageLabels.d }}</strong><br>
							<span v-html="amount(coverages.d)"></span>
						</p>
						<p v-show="quotetype == 'Home'">
							<strong>{{ coverageLabels.f }}</strong><br>
							<span v-html="amount(coverages.f)"></span>
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
export default {
	props: {
		insquote: {
			type: Object,
			required: true
		},
		quotetype: {
			type: String,
			required: true
		},
		phonedigits: {
			type: String,
			required: true
		},
		iconvariant: {
			type: String,
			required: true
		}, 
		theme: {
			type: String, 
			default: 'default'
		}
	},
	data: function() {
		return {
			assetRoot: 'cdn/', /* TODO: Change to S3 later */
			expanded: false
		}
	},
	computed: {
		hasTotal: function() {
			return (this.insquote.productQuotes != undefined
				&& this.insquote.productQuotes[this.quotetype] != undefined
				&& this.insquote.productQuotes[this.quotetype].coverages != undefined
				&& this.insquote.productQuotes[this.quotetype].coverages.total != undefined
				&& isNaN(this.insquote.productQuotes[this.quotetype].coverages.total) === false
				&& this.insquote.productQuotes[this.quotetype].coverages.total != null
				&& this.insquote.productQuotes[this.quotetype].coverages.total > 0);
		},
		isError: function() {
			if (this.insquote.errored === true) { return true; }
			if (this.insquote.productQuotes != undefined
				&& this.insquote.productQuotes[this.quotetype] != undefined
				&& this.insquote.productQuotes[this.quotetype].errored === true) {
					return true;
			}
			return false;
		},
		hasTel: function() {
			return ((typeof this.insquote.carrier_phone) == 'string' && this.insquote.carrier_phone.length > 0);
		},
		singleQuote: function() {
			if (this.insquote.productQuotes != undefined && this.insquote.productQuotes[this.quotetype] != undefined) {
				return this.insquote.productQuotes[this.quotetype];
			}
			return {
				coverages: { a: '', b: '', c: '', d: '', e: '', f: '', total: '' },
				finished: false,
				term: 0,
				term_premium: 0
			};
		},
		coverages: function() {
			if (this.insquote.productQuotes == undefined
				|| this.insquote.productQuotes[this.quotetype] == undefined
				|| this.insquote.productQuotes[this.quotetype].coverages == undefined) {
					return { a: '', b: '', c: '', d: '', e: '', f: '', total: '' };
			}
			return this.insquote.productQuotes[this.quotetype].coverages;
		},
		tel: function() {
			if (this.hasTel === false) { return ''; }
			let out = this.insquote.carrier_phone;
			if (out.substring(0, 1) == '+') { out = out.substring(1); } // Strip the + symbol from the phone number.
			if (out.length == 11 && out.substring(0, 1) == '1') {
				// 10-digit phone number. Add dots.
				return out.substring(1, 4) + '.' + out.substring(4, 7) + '.' + out.substring(7);
			}
			// Unknown format. Return the value raw.
			return out;
		},
		isAutoQuote: function() {
			return (this.quotetype == 'Auto');
		},
		coverageLabels: function() {
			if (this.quotetype == 'Auto') {
				return {
					a: 'Bodily injury',
					b: 'Medical payments',
					c: 'Property damage',
					d: 'Underinsured motorist',
					e: 'Uninsured motorist',
					f: ''
				};
			}
			return {
				a: 'Repair/rebuild your home',
				b: 'Repair/rebuild other structures',
				c: 'Personal possessions',
				d: 'Temporary living expenses',
				e: 'Personal liability',
				f: 'Medical payments to others'
			};
		}, 
		closeXimage: function() {
			let out = this.asset('x-circle.png');
			if (this.theme == 'seiu') {
				out = this.asset('x-circle-e01719.png');
			}
			return out;
		}, 
		callIM_cta: function() {
			if (this.theme == 'gallagher') {
				return 'Please call InsuraMatch, proud partners of Gallagher Marketplace, for more information.';
			}
			return 'Please call InsuraMatch for more information.';
		}
	},
	methods: {
		asset: function(str) {
			return this.assetRoot + str;
		},
		amount: function(n) {
			if (n == null) { return 'Call for Details'; }
			const lookups = {
				'50000/100000': `$50,000/$100,000`,
				'100000/300000': `$100,000/$300,000`,
				'250000/500000': `$250,000/$500,000`
			};
			if (Object.keys(lookups).indexOf(n) > -1) {
				return lookups[n];
			}
			return '$' + n.toLocaleString();
		},
		startScheduleCall: function() {
			window.Rates.showScheduleCallForm();
		},
		numFormat: function(n) {
			if (isNaN(n) === true || n == null) { return ''; }
			return '' + n.toLocaleString();
		},
		expand: function() {
			if (this.hasTotal === true) {
				this.expanded = true;
			}
		},
		collapse: function() {
			this.expanded = false;
		}, 
		scrollToDisclaimer: function() {
			window.scrollToAndPulse('.quotesDisclaimer:visible');
		}
	}
}
</script>